.floatingdiv{
    justify-content: space-around;
    display: flex;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 16px;
    align-items: center;
    padding: 0px 20px 0px 0px;
    height: 3.2rem;
    
}

.floatingdiv>img{
    transform: scale(0.4);
}
.floatingdiv>span{
    font-family: sans-serif;
    font-size: 16px;
    color: black;
}
